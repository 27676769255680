import React from 'react';

type Props = {
    icon: string;
    size: number;
    className?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MaterialIcon = ({ icon, size, className }: Props) => {
    return (
        <span
            className={`material-icons ${className}`}
            style={{ fontSize: `${size}px` }}
        >
            {icon}
        </span>
    );
};
