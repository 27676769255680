import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { updateTableData } from './Actions';
import { Spinner } from '../../components';
import moment, { Moment } from 'moment';
import { Category } from '../../interfaces';
import { monthDiff } from '../../util/numbers';
import { create_concept } from '../../api';
import { HomePageHeader } from '../../components/HomePageHeader';
import { ModalTable } from '../../components/ModalTable';
import { TotalsRow } from '../../components/TotalsRow';
import { MainTableHeader } from '../../components/MainTableHeader';
import { AveragesRow } from '../../components/AveragesRow';
import { TableRow } from '../../components/TableRow';

const mapStateToProps = (state: RootState) => {
    return {
        home_state: state.home,
    };
};

const connector = connect(mapStateToProps, {
    updateTableData,
});

type Props = ConnectedProps<typeof connector>;

interface State {
    selectedCategory: Category | null;
    selectedPrice: number | undefined;
    selectedDescription: string;
    clickedDate: Moment | null;
    clickedCategory: Category | null;
    shakeDate: string | null;
    shakeCategory: Category | null;
    collapseDate: Moment | null;
    flashes: {
        type: string;
        title: string;
        message: string;
        timestamp: number;
    }[];
}

class Index extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCategory: null,
            selectedPrice: undefined,
            selectedDescription: '',
            clickedDate: null,
            clickedCategory: null,
            shakeDate: null,
            shakeCategory: null,
            collapseDate: moment(),
            flashes: [],
        };
    }

    componentDidMount() {
        this.props.updateTableData();
    }

    getMappedCategories() {
        const { categories } = this.props.home_state;
        return [...categories].map((category) => ({
            id: category.id,
            name: category.name,
            value: category.name,
        }));
    }

    renderDetailModal() {
        const { clickedCategory, clickedDate, shakeDate, shakeCategory } =
            this.state;
        const { concepts } = this.props.home_state;
        if (clickedCategory && clickedDate) {
            return (
                <div className="detail-modal">
                    <div className="detail-modal-background">
                        <div className="detail-modal-content">
                            <div className="row">
                                <div className="col-9 col-sm-10">
                                    <h2>
                                        {`${clickedCategory.name} 
                                        ${clickedDate.format('YYYY MMMM')}`}
                                    </h2>
                                </div>
                                <div className="col-3 col-sm-2 d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        style={{ height: '36px' }}
                                        onClick={() =>
                                            this.setState({
                                                clickedCategory: null,
                                                clickedDate: null,
                                            })
                                        }
                                    >
                                        <span className="material-icons">
                                            close
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <ModalTable
                                concepts={concepts}
                                clickedCategory={clickedCategory}
                                clickedDate={clickedDate}
                                shakeDate={shakeDate}
                                shakeCategory={shakeCategory}
                                onShakeStart={() => {
                                    this.setState({
                                        shakeDate:
                                            clickedDate?.format('YYYY-MM'),
                                        shakeCategory: clickedCategory,
                                    });
                                }}
                                onShakeEnd={() => {
                                    this.setState({
                                        shakeDate: null,
                                        shakeCategory: null,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHeader() {
        const { selectedCategory, selectedPrice, selectedDescription } =
            this.state;
        const mappedCategories = this.getMappedCategories();
        const { categories } = this.props.home_state;
        return (
            <HomePageHeader
                categories={categories}
                mappedCategories={mappedCategories}
                updateTableData={() => this.props.updateTableData()}
                selectedCategory={selectedCategory}
                selectedPrice={selectedPrice}
                selectedDescription={selectedDescription}
                sendConcept={() => this.sendConcept()}
                onCategoryChange={(category) =>
                    this.setState({ selectedCategory: category })
                }
                onPriceChange={(price: number | undefined) =>
                    this.setState({ selectedPrice: price })
                }
                onDescriptionChange={(description: string) =>
                    this.setState({ selectedDescription: description })
                }
            />
        );
    }

    addFlash(type: string, title: string, message: string) {
        this.setState({
            flashes: [
                ...this.state.flashes,
                {
                    type: type,
                    title: title,
                    message: message,
                    timestamp: parseInt(moment().format('YYYYMMDDHHmmssSS')),
                },
            ],
        });
    }

    sendConcept() {
        const { selectedCategory, selectedPrice, selectedDescription } =
            this.state;
        if (!selectedCategory) {
            return this.addFlash(
                'danger',
                'Category error',
                'Category cant be empty'
            );
        }
        if (!selectedPrice || selectedPrice <= 0) {
            return this.addFlash('danger', 'Price error', 'Price cant be 0');
        }

        create_concept(selectedCategory, selectedPrice, selectedDescription)
            .then((status) => {
                if (!status) {
                    return this.addFlash(
                        'danger',
                        'Connection error',
                        'Some error occurred creating the concept'
                    );
                }
                this.setState(
                    {
                        selectedCategory: null,
                        selectedPrice: undefined,
                        selectedDescription: '',
                    },
                    () => {
                        this.props.updateTableData();
                        return this.addFlash(
                            'success',
                            'Concept created',
                            'Concept created successfully'
                        );
                    }
                );
            })
            .catch((err) => console.error(err));
    }

    collapseMonth(date: Moment) {
        if (
            date &&
            this.state.collapseDate?.format('YYYYMMDD') ===
                date.format('YYYYMMDD')
        ) {
            this.setState({ collapseDate: null });
        } else {
            this.setState({ collapseDate: date });
        }
    }

    rowKeys(firstDate: Moment | null, lastDate: Moment | null) {
        if (firstDate == null || lastDate == null) {
            return [];
        }
        const array = Array.from(
            Array(monthDiff(moment(lastDate), firstDate)).keys()
        );
        array.push(array.length);
        return array;
    }

    renderTable() {
        const mappedCategories = this.getMappedCategories();
        const { concepts, loading, firstDate, lastDate } =
            this.props.home_state;
        const rowKeysArray = this.rowKeys(firstDate, lastDate);
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                    <MainTableHeader mappedCategories={mappedCategories} />
                    <tbody>
                        <AveragesRow
                            mappedCategories={mappedCategories}
                            rowKeysArray={rowKeysArray}
                            concepts={concepts}
                        />
                        {rowKeysArray.map((key, index) => (
                            <TableRow
                                key={`table-row-${index}`}
                                lastDate={lastDate}
                                keyLoop={key}
                                concepts={concepts}
                                mappedCategories={mappedCategories}
                                collapseDate={this.state.collapseDate}
                                onCellClick={(category: Category) => {
                                    this.setState({
                                        clickedCategory: category,
                                        clickedDate: moment(lastDate).subtract(
                                            key,
                                            'months'
                                        ),
                                    });
                                }}
                                onCollapseMonth={() =>
                                    this.collapseMonth(
                                        moment(lastDate).subtract(key, 'months')
                                    )
                                }
                            />
                        ))}
                        <TotalsRow
                            concepts={concepts}
                            mappedCategories={mappedCategories}
                        />
                    </tbody>
                </table>
            </div>
        );
    }

    renderToasts() {
        const { flashes } = this.state;
        return (
            <>
                {flashes.map((flash, index) => (
                    <div
                        key={`flash-${index}`}
                        className="position-fixed bottom-0 end-0 p-3"
                        style={{
                            zIndex: 11,
                            marginBottom: 100 * (flashes.length - index - 1),
                        }}
                    >
                        <div className="toast show bg-white" role="alert">
                            <div className="toast-header">
                                <div
                                    style={{ width: 20, height: 20 }}
                                    className={`bg-${flash.type} rounded me-2`}
                                />
                                <strong className="me-auto">
                                    {flash.title}
                                </strong>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="toast"
                                    onClick={() => {
                                        this.setState({
                                            flashes: this.state.flashes.filter(
                                                (element) =>
                                                    element.timestamp !==
                                                    flash.timestamp
                                            ),
                                        });
                                    }}
                                />
                            </div>
                            <div className="toast-body">{flash.message}</div>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderDetailModal()}
                <div className="px-3">
                    {this.renderHeader()}
                    <div className="row">
                        <div className="col-12">{this.renderTable()}</div>
                    </div>
                </div>
                {this.renderToasts()}
            </>
        );
    }
}

export default connector(Index);
