import React from 'react';
import { Concept } from '../interfaces/Concept';
import { Category } from '../interfaces/Category';
import { MaterialIcon } from './MaterialIcon';

type Props = {
    concepts: Concept[];
    clickedCategory: Category | null;
    clickedDate: moment.Moment | null;
    shakeDate: string | null;
    shakeCategory: Category | null;
    onShakeStart: () => void;
    onShakeEnd: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ModalTable = ({
    concepts,
    clickedCategory,
    clickedDate,
    shakeDate,
    shakeCategory,
    onShakeStart,
    onShakeEnd,
}: Props) => {
    const total =
        Math.round(
            concepts
                .filter(
                    (concept) =>
                        clickedCategory &&
                        clickedDate &&
                        concept.category === clickedCategory.name &&
                        concept.date
                            .format('YYYY-MM')
                            .includes(clickedDate.format('YYYY-MM'))
                )
                .reduce(
                    (totalPrice, concept) => totalPrice + concept.price,
                    0
                ) * 100
        ) / 100;

    return (
        <table className="table">
            <thead>
                <tr>
                    <th style={{ minWidth: '110px' }}>Date</th>
                    <th>Description</th>
                    <th className="text-end">Price</th>
                </tr>
            </thead>
            <tbody>
                {concepts
                    .filter(
                        (concept) =>
                            clickedCategory &&
                            clickedDate &&
                            concept.category === clickedCategory.name &&
                            concept.date
                                .format('YYYY-MM')
                                .includes(clickedDate.format('YYYY-MM'))
                    )
                    .sort((a, b) => (a.date > b.date ? 1 : -1))
                    .map((concept, index) => (
                        <tr key={index}>
                            <td>{concept.date.format('YYYY-MM-DD')}</td>
                            <td>{concept.description}</td>
                            <td className="text-end">
                                {concept.price.toFixed(2)}€
                            </td>
                        </tr>
                    ))}
                <tr>
                    <td>
                        <b>TOTAL</b>
                    </td>
                    <td
                        className={`text-end ${total !== 0 ? 'pointer' : ''}`}
                        onClick={() => {
                            if (total !== 0) {
                                onShakeStart();
                                setTimeout(() => onShakeEnd(), 50);
                            }
                        }}
                    >
                        {total !== 0 && (
                            <MaterialIcon
                                icon="content_copy"
                                className={
                                    shakeDate ===
                                        clickedDate?.format('YYYY-MM') &&
                                    shakeCategory?.name ===
                                        clickedCategory?.name
                                        ? 'shake'
                                        : ''
                                }
                                size={16}
                            />
                        )}
                    </td>
                    <td className="text-end">
                        <b>{total.toFixed(2)}€</b>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
