import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import {} from './Actions';
import { InputPassword } from '../../components';
import cookies from '../../util/cookies';
import { are_equal_strings } from '../../util/form';

const mapStateToProps = (state: RootState) => {
    return {
        login_state: state.login,
    };
};

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

interface State {
    password: string;
    passwordError: string;
}

class Index extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            password: '',
            passwordError: '',
        };
    }

    componentDidMount() {
        document.title = 'Money';
    }

    login() {
        if (
            are_equal_strings(
                this.state.password,
                process.env.REACT_APP_GLOBAL_PWS || ''
            )
        ) {
            this.setState({ passwordError: '' });
            cookies.set('password', this.state.password, { path: '/' });
        } else {
            this.setState({ passwordError: 'Wrong password' });
        }
    }

    render() {
        return (
            <div className="cotainer">
                <div className="row justify-content-center px-2">
                    <div className="col-md-8">
                        <h2>Money</h2>
                        <div className="card">
                            <div className="card-header">Password</div>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label
                                        htmlFor="password"
                                        className="col-md-4 col-form-label text-md-right"
                                    >
                                        Password
                                    </label>
                                    <div className="col-md-6">
                                        <InputPassword
                                            id="password"
                                            value={this.state.password}
                                            helpText={this.state.passwordError}
                                            onChange={(event) => {
                                                this.setState({
                                                    password:
                                                        event.target.value,
                                                });
                                            }}
                                            onEnter={() => this.login()}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 offset-md-4 pt-2 ps-md-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.login()}
                                    >
                                        Entrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(Index);
