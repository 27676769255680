import React from 'react';
import TextField from '@material-ui/core/TextField';

type Props = {
    id: string;
    label?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    helpText?: string;
    onChange?: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onBlur?: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InputText = ({
    id,
    label,
    value,
    placeholder,
    disabled,
    helpText,
    onChange,
    onBlur,
    onKeyUp,
}: Props) => {
    return (
        <TextField
            type="text"
            id={id}
            label={label}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            size="small"
            fullWidth
            disabled={disabled}
            error={!!helpText}
            helperText={helpText}
        />
    );
};
