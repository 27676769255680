import React from 'react';
import {
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    FormHelperText,
} from '@material-ui/core';
import { SelectOption } from '../interfaces';

type Props = {
    id: string;
    label: string;
    disabled?: boolean;
    options?: SelectOption[];
    value?: string;
    emptyValue?: boolean;
    helpText?: string;
    onChange?: (value?: string) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InputSelect = ({
    id,
    label,
    disabled,
    options,
    value,
    emptyValue,
    helpText,
    onChange,
}: Props) => {
    let optionsList = options;
    if (emptyValue) {
        optionsList = [
            { value: '', name: 'Vacío' } as SelectOption,
            ...(options || []),
        ];
    }
    return (
        <FormControl error={!!helpText} fullWidth>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                disabled={disabled}
                id={`${id}-select`}
                value={value}
                onChange={(e) => onChange?.(e.target.value as string)}
                label={label}
                inputProps={{
                    name: label,
                    id: id,
                }}
            >
                {(optionsList || []).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{helpText}</FormHelperText>
        </FormControl>
    );
};
