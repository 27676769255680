export const is_empty_string = (text: string | undefined): boolean => {
    return !text || text === '';
};

export const is_email = (email: string): boolean => {
    const re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
    return re.test(email);
};

export const are_equal_strings = (value1: string, value2: string): boolean => {
    return value1 === value2;
};
