import React from 'react';
import { Badge } from './Badge';
import { MaterialIcon } from './MaterialIcon';
import {
    decrement,
    formatNumber,
    sumMonthCategory,
} from '../util/numbers';
import moment from 'moment';
import { Concept } from '../interfaces/Concept';
import { Category } from '../interfaces/Category';

type Props = {
    concepts: Concept[];
    lastDate: moment.Moment | null;
    key: number;
    category: Category;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BadgeSuccess = ({ concepts, lastDate, key, category }: Props) => {
    return (
        <Badge type="success">
            {formatNumber(
                decrement(
                    sumMonthCategory(
                        concepts,
                        moment(lastDate).subtract(key, 'months'),
                        category
                    ),
                    sumMonthCategory(
                        concepts,
                        moment(lastDate).subtract(key + 1, 'months'),
                        category
                    )
                )
            )}
            %
            <MaterialIcon icon="south" size={10} />
        </Badge>
    );
};
