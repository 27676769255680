import moment from 'moment';
import React from 'react';
import { MaterialIcon } from './MaterialIcon';
import { formatNumber, sumMonthCategory, sumMonthTotal } from '../util/numbers';
import { Category, Concept } from '../interfaces';
import { BadgeSuccessTotal } from './BadgeSuccessTotal';
import { BadgeDangerTotal } from './BadgeDangerTotal';
import { BadgeSuccess } from './BadgeSuccess';
import { BadgeDanger } from './BadgeDanger';

type Props = {
    lastDate: moment.Moment | null;
    keyLoop: number;
    concepts: Concept[];
    mappedCategories: Category[];
    collapseDate: moment.Moment | null;
    onCellClick: (category: Category) => void;
    onCollapseMonth: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TableRow = ({
    lastDate,
    keyLoop: key,
    concepts,
    mappedCategories,
    collapseDate,
    onCellClick,
    onCollapseMonth,
}: Props) => {
    const displayBadgeSuccessTotal =
        sumMonthTotal(concepts, moment(lastDate).subtract(key, 'months')) <
            sumMonthTotal(
                concepts,
                moment(lastDate).subtract(key + 1, 'months')
            ) &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    const displayBadgeDangerTotal =
        sumMonthTotal(concepts, moment(lastDate).subtract(key, 'months')) >=
            sumMonthTotal(
                concepts,
                moment(lastDate).subtract(key + 1, 'months')
            ) &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    const displayBadgeSuccess = (category: Category) =>
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) <
            sumMonthCategory(
                concepts,
                moment(lastDate).subtract(key + 1, 'months'),
                category
            ) &&
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) !== 0 &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    const displayBadgeDanger = (category: Category) =>
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) >=
            sumMonthCategory(
                concepts,
                moment(lastDate).subtract(key + 1, 'months'),
                category
            ) &&
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) !== 0 &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    return (
        <tr>
            <td className="text-center">
                {`${moment(lastDate)
                    .subtract(key, 'months')
                    .format('YYYY MMMM')
                    .substring(0, 8)}`}
                <span className="pointer" onClick={onCollapseMonth}>
                    <MaterialIcon
                        icon={
                            moment(lastDate)
                                .subtract(key, 'months')
                                .format('YYYYMMDD') ===
                            collapseDate?.format('YYYYMMDD')
                                ? 'expand_less'
                                : 'expand_more'
                        }
                        className="align-middle"
                        size={24}
                    />
                </span>
            </td>
            <td className="text-center col-border-l-r">
                <>
                    <b>
                        {formatNumber(
                            sumMonthTotal(
                                concepts,
                                moment(lastDate).subtract(key, 'months')
                            )
                        )}
                        €
                    </b>
                    <br />
                    <b>
                        {displayBadgeSuccessTotal && (
                            <BadgeSuccessTotal
                                concepts={concepts}
                                lastDate={lastDate}
                                key={key}
                            />
                        )}
                        {displayBadgeDangerTotal && (
                            <BadgeDangerTotal
                                concepts={concepts}
                                lastDate={lastDate}
                                key={key}
                            />
                        )}
                    </b>
                </>
            </td>
            {mappedCategories.map((category, index) => (
                <React.Fragment key={`big-cell-${index}`}>
                    <td
                        className="col-border-l"
                        style={
                            sumMonthCategory(
                                concepts,
                                moment(lastDate).subtract(key, 'months'),
                                category
                            ) !== 0
                                ? { cursor: 'pointer' }
                                : {}
                        }
                    ></td>
                    <td
                        className="text-center col-border-r pointer"
                        onClick={() => onCellClick(category)}
                    >
                        {sumMonthCategory(
                            concepts,
                            moment(lastDate).subtract(key, 'months'),
                            category
                        ) !== 0 && (
                            <>
                                {formatNumber(
                                    sumMonthCategory(
                                        concepts,
                                        moment(lastDate).subtract(
                                            key,
                                            'months'
                                        ),
                                        category
                                    )
                                )}
                                €
                            </>
                        )}
                        {displayBadgeSuccess(category) && (
                            <BadgeSuccess
                                concepts={concepts}
                                lastDate={lastDate}
                                key={key}
                                category={category}
                            />
                        )}
                        {displayBadgeDanger(category) && (
                            <BadgeDanger
                                concepts={concepts}
                                lastDate={lastDate}
                                key={key}
                                category={category}
                            />
                        )}
                    </td>
                </React.Fragment>
            ))}
        </tr>
    );
};
