import React from 'react';
import { Category } from '../interfaces';

type Props = {
    mappedCategories: Category[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MainTableHeader = ({ mappedCategories }: Props) => {
    return (
        <thead>
            <tr>
                <th />
                <th className="text-center col-border-l-t-r">TOTAL</th>
                {mappedCategories.map((category, index) => (
                    <th
                        key={index}
                        className="text-center col-border-l-t-r"
                        colSpan={2}
                    >
                        {category.name}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
