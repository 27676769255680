import {
    UPDATE_CATEGORIES,
    UPDATE_CONCEPTS,
    UPDATE_FIRST_DATE,
    UPDATE_LAST_DATE,
    UPDATE_LOADING,
    Actions,
    AppThunk,
} from './ActionTypes';
import {
    get_categories,
    get_concepts,
    get_first_concepts,
    get_last_concepts,
} from '../../api';
import { category_map } from '../../interfaces/Category.maps';
import { concept_map } from '../../interfaces/Concept.maps';

export const updateTableData = (): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true,
        } as Actions);

        let categoryList = await get_categories();
        categoryList = categoryList.data.map(category_map);
        dispatch({
            type: UPDATE_CATEGORIES,
            payload: categoryList,
        } as Actions);

        let conceptList = await get_concepts();
        conceptList = conceptList.data.map(concept_map);
        dispatch({
            type: UPDATE_CONCEPTS,
            payload: conceptList,
        } as Actions);

        let firstConcept = await get_first_concepts();
        firstConcept = firstConcept.data.map(concept_map);
        dispatch({
            type: UPDATE_FIRST_DATE,
            payload: firstConcept[0].date,
        } as Actions);

        let lastConcept = await get_last_concepts();
        lastConcept = lastConcept.data.map(concept_map);
        dispatch({
            type: UPDATE_LAST_DATE,
            payload: lastConcept[0].date,
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false,
        } as Actions);
    };
};
