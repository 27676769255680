import React from 'react';
import TextField from '@material-ui/core/TextField';

type Props = {
    id: string;
    label: string;
    value: number | '';
    disabled?: boolean;
    helpText?: string;
    onChange?: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InputNumber = ({
    id,
    label,
    value,
    onChange,
    disabled,
    helpText,
}: Props) => {
    return (
        <TextField
            type="number"
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            error={!!helpText}
            helperText={helpText}
        />
    );
};
