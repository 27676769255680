import { Actions } from './ActionTypes';
import { Reducer } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

const INITIAL_STATE: State = {};

const reducer: Reducer<State, Actions> = (
    state = INITIAL_STATE,
    action: Actions
) => {
    switch (action.type) {
    default:
        return state;
    }
};

export default reducer;
