import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { Category } from '../interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const with_auth = (config: AxiosRequestConfig = {}) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const get_categories = () => {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_CATEGORIES_SCHEMA_ID}/data?sort=name&limit=1000`,
            with_auth()
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const get_concepts = () => {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_CONCEPTS_SCHEMA_ID}/data?limit=1000`,
            with_auth()
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const create_concept = (
    category: Category,
    price: number,
    description: string
) => {
    const object = {
        fields: {
            date: moment().format('YYYY-MM-DD'),
            category: category,
            price: price,
            description: description,
        },
    };
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_CONCEPTS_SCHEMA_ID}/data`,
            object,
            with_auth()
        )
        .then((response) => {
            if (response.status === 200) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const get_first_concepts = () => {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_CONCEPTS_SCHEMA_ID}/data?sort=date&limit=1`,
            with_auth()
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const get_last_concepts = () => {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_CONCEPTS_SCHEMA_ID}/data?sort=date(desc)&limit=1`,
            with_auth()
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            return [];
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
};
