import React from 'react';
import { Category, Concept } from '../interfaces';
import { average, formatNumber, sumMonthCategory } from '../util/numbers';
import moment from 'moment';

type Props = {
    mappedCategories: Category[];
    rowKeysArray: number[];
    concepts: Concept[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AveragesRow = ({ mappedCategories, rowKeysArray, concepts }: Props) => {
    return (
        <tr>
            <td className="text-center">
                <b>AVERAGE</b>
            </td>
            <td className="col-border-l-r" />
            {mappedCategories.map((category, index) => (
                <th
                    key={index}
                    className="text-center col-border-l-r"
                    colSpan={2}
                >
                    {formatNumber(
                        average(
                            rowKeysArray.map((key) =>
                                sumMonthCategory(
                                    concepts,
                                    moment().subtract(key, 'months'),
                                    category
                                )
                            )
                        )
                    )}
                    €
                </th>
            ))}
        </tr>
    );
};
