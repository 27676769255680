import React from 'react';
import { Badge } from './Badge';
import { MaterialIcon } from './MaterialIcon';
import { decrement, formatNumber, sumMonthTotal } from '../util/numbers';
import moment from 'moment';
import { Concept } from '../interfaces/Concept';

type Props = {
    concepts: Concept[];
    lastDate: moment.Moment | null;
    key: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BadgeSuccessTotal = ({ concepts, lastDate, key }: Props) => {
    return (
        <Badge type="success">
            {formatNumber(
                decrement(
                    sumMonthTotal(
                        concepts,
                        moment(lastDate).subtract(key, 'months')
                    ),
                    sumMonthTotal(
                        concepts,
                        moment(lastDate).subtract(key + 1, 'months')
                    )
                )
            )}
            %
            <MaterialIcon icon="south" size={10} />
        </Badge>
    );
};
