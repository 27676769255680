import React from 'react';
import { InputText } from './InputText';
import { InputNumber } from './InputNumber';
import { InputSelect } from './InputSelect';
import { MaterialIcon } from './MaterialIcon';
import { Category } from '../interfaces/Category';

type Props = {
    categories: Category[];
    mappedCategories: { name: string; value: string }[];
    updateTableData: () => void;
    selectedCategory: Category | null;
    selectedPrice: number | undefined;
    selectedDescription: string;
    sendConcept: () => void;
    onCategoryChange: (category: Category) => void;
    onPriceChange: (price: number | undefined) => void;
    onDescriptionChange: (description: string) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const HomePageHeader = ({
    categories,
    mappedCategories,
    updateTableData,
    selectedCategory,
    selectedPrice,
    selectedDescription,
    sendConcept,
    onCategoryChange,
    onPriceChange,
    onDescriptionChange,
}: Props) => {
    return (
        <div className="row">
            <div className="col-sm-12 col-md-3 d-inline-flex">
                <h2 className="my-2">Money</h2>
                <span
                    className="align-self-center mt-1 ms-2 pointer"
                    onClick={updateTableData}
                >
                    <MaterialIcon icon="sync" size={24} />
                </span>
            </div>
            <div className="col-sm-12 col-md-2 mt-2 mb-1 align-self-end">
                <InputSelect
                    id="insert-category"
                    label="Category"
                    options={mappedCategories}
                    value={selectedCategory ? selectedCategory.name : ''}
                    onChange={(categoryId) => {
                        onCategoryChange(
                            categories.filter(
                                (category) => category.name === categoryId
                            )[0]
                        );
                    }}
                />
            </div>
            <div className="col-sm-12 col-md-2 mt-2 mb-2 align-self-end">
                <InputNumber
                    id="add-price"
                    label="Price"
                    value={selectedPrice ? selectedPrice : ''}
                    onChange={(event) => {
                        onPriceChange(
                            event?.target.value
                                ? Number(event?.target.value)
                                : undefined
                        );
                    }}
                />
            </div>
            <div className="col-sm-12 col-md-3 mt-2 mb-2 align-self-end">
                <InputText
                    id="add-description"
                    label="Description"
                    value={selectedDescription}
                    onChange={(event) => {
                        onDescriptionChange(event?.target.value);
                    }}
                />
            </div>
            <div className="col-sm-12 col-md-1 mt-2 mb-2 align-self-end">
                <button className="btn btn-primary mt-0" onClick={sendConcept}>
                    Add
                </button>
            </div>
        </div>
    );
};
