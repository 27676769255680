import React, { ReactNode } from 'react';

type Props = {
    type: string;
    children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Badge = ({ type, children }: Props) => {
    return <span className={`badge bg-${type}`}>{children}</span>;
};
