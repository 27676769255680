import { UPDATE_PASSWORD, Actions } from './ActionTypes';
import { Reducer } from 'redux';

export interface State {
    password?: string | null;
}

const INITIAL_STATE: State = {
    password: null,
};

const reducer: Reducer<State, Actions> = (
    state = INITIAL_STATE,
    action: Actions
) => {
    switch (action.type) {
    case UPDATE_PASSWORD:
        return { ...state, password: action.payload };
    default:
        return state;
    }
};

export default reducer;
