import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../reducers';
import { Category, Concept } from '../../interfaces';
import { Moment } from 'moment';

export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_CONCEPTS = 'UPDATE_CONCEPTS';
export const UPDATE_FIRST_DATE = 'UPDATE_FIRST_DATE';
export const UPDATE_LAST_DATE = 'UPDATE_LAST_DATE';
export const UPDATE_LOADING = 'UPDATE_LOADING';

export interface UpdateCategoriesAction {
    type: typeof UPDATE_CATEGORIES;
    payload: Category[];
}

export interface UpdateConceptsAction {
    type: typeof UPDATE_CONCEPTS;
    payload: Concept[];
}

export interface UpdateFirstDateAction {
    type: typeof UPDATE_FIRST_DATE;
    payload: Moment;
}

export interface UpdateLastDateAction {
    type: typeof UPDATE_LAST_DATE;
    payload: Moment;
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING;
    payload: boolean;
}

export type Actions =
    | UpdateCategoriesAction
    | UpdateConceptsAction
    | UpdateFirstDateAction
    | UpdateLastDateAction
    | UpdateLoadingAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
