import React from 'react';
import { formatNumber, sumCategoryTotal } from '../util/numbers';
import { Category, Concept } from '../interfaces';

type Props = {
    concepts: Concept[];
    mappedCategories: Category[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TotalsRow = ({ concepts, mappedCategories }: Props) => {
    return (
        <tr>
            <td className="text-center">
                <b>TOTAL</b>
            </td>
            <td className="col-border-l-r col-border-b text-center">
                <b>
                    {formatNumber(
                        concepts.reduce(
                            (totalPrice, concept) => totalPrice + concept.price,
                            0
                        )
                    )}
                    €
                </b>
            </td>
            {mappedCategories.map((category, index) => (
                <React.Fragment key={`big-cell-${index}`}>
                    <td className="text-center col-border-r-b" colSpan={2}>
                        <b>
                            {formatNumber(sumCategoryTotal(concepts, category))}
                            €
                        </b>
                    </td>
                </React.Fragment>
            ))}
        </tr>
    );
};
