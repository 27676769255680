import {
    UPDATE_CATEGORIES,
    UPDATE_CONCEPTS,
    UPDATE_FIRST_DATE,
    UPDATE_LAST_DATE,
    UPDATE_LOADING,
    Actions,
} from './ActionTypes';
import { Reducer } from 'redux';
import { Category, Concept } from '../../interfaces';
import { Moment } from 'moment';

export interface State {
    categories: Category[];
    concepts: Concept[];
    firstDate: Moment | null;
    lastDate: Moment | null;
    loading: boolean;
}

const INITIAL_STATE: State = {
    categories: [],
    concepts: [],
    firstDate: null,
    lastDate: null,
    loading: false,
};

const reducer: Reducer<State, Actions> = (
    state = INITIAL_STATE,
    action: Actions
) => {
    switch (action.type) {
    case UPDATE_CATEGORIES:
        return { ...state, categories: action.payload };
    case UPDATE_CONCEPTS:
        return { ...state, concepts: action.payload };
    case UPDATE_FIRST_DATE:
        return { ...state, firstDate: action.payload };
    case UPDATE_LAST_DATE:
        return { ...state, lastDate: action.payload };
    case UPDATE_LOADING:
        return { ...state, loading: action.payload };
    default:
        return state;
    }
};

export default reducer;
