import { combineReducers } from 'redux';
import AuthReducer from './auth/Reducer';
import HomeReducer from '../pages/home/Reducer';
import LoginReducer from '../pages/login/Reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    home: HomeReducer,
    login: LoginReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
