import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Spinner = () => {
    return (
        <div className="row justify-content-center">
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
