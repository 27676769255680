import './App.css';
import React from 'react';
import { RootState } from './reducers';
import { connect, ConnectedProps } from 'react-redux';
import { updatePassword } from './reducers/auth/Actions';
import cookies from './util/cookies';
import { are_equal_strings } from './util/form';

import Home from './pages/home/Index';
import Login from './pages/login/Index';

const mapStateToProps = (state: RootState) => {
    return {
        auth_state: state.auth,
    };
};

const connector = connect(mapStateToProps, { updatePassword });

class App extends React.Component<ConnectedProps<typeof connector>> {
    componentDidMount() {
        const password = cookies.get('password');
        this.props.updatePassword(password);
        cookies.addChangeListener((options) => {
            if (options.name === 'password') {
                this.props.updatePassword(options.value);
            }
        });
    }

    render() {
        const { password } = this.props.auth_state;
        return (
            <>
                {password !== '' &&
                    process.env.REACT_APP_GLOBAL_PWS !== '' &&
                    !are_equal_strings(
                        password || '',
                        process.env.REACT_APP_GLOBAL_PWS || ''
                    ) && <Login />}
                {password !== '' &&
                    process.env.REACT_APP_GLOBAL_PWS !== '' &&
                    are_equal_strings(
                        password || '',
                        process.env.REACT_APP_GLOBAL_PWS || ''
                    ) && <Home />}
            </>
        );
    }
}

export default connector(App);
